import React, {useEffect, useRef, useState} from 'react';
import {Button} from 'primereact/button';
import './App.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import {
    Autocomplete,
    Box,
    Container,
    Grid, Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    TextField, Toolbar,
    Typography
} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: "#091428"
        }
    },
});

export interface Champion {
    type: string;
    format: string;
    version: string;
    data: Data;
}

export interface Data {
    Champion: Champion;
}

export interface Champion {
    id: string;
    key: string;
    name: string;
    title: string;
    image: Image;
    skins: Skin[];
    lore: string;
    blurb: string;
    allytips: string[];
    enemytips: string[];
    tags: string[];
    partype: string;
    info: Info;
    stats: { [key: string]: number };
    spells: Spell[];
    passive: Passive;
    recommended: any[];
}

export interface Image {
    full: string;
    sprite: string;
    group: string;
    x: number;
    y: number;
    w: number;
    h: number;
}

export interface Info {
    attack: number;
    defense: number;
    magic: number;
    difficulty: number;
}

export interface Passive {
    name: string;
    description: string;
    image: Image;
}

export interface Skin {
    id: string;
    num: number;
    name: string;
    chromas: boolean;
}

export interface Spell {
    id: string;
    name: string;
    description: string;
    tooltip: string;
    leveltip: Leveltip;
    maxrank: number;
    cooldown: number[];
    cooldownBurn: string;
    cost: number[];
    costBurn: string;
    datavalues: Datavalues;
    effect: Array<number[] | null>;
    effectBurn: Array<null | string>;
    vars: any[];
    costType: string;
    maxammo: string;
    range: number[];
    rangeBurn: string;
    image: Image;
    resource: string;
}

export interface Datavalues {
}

export interface Leveltip {
    label: string[];
    effect: string[];
}


function App(props: any) {
    const [champions, setChampions] = useState<Array<string>>([]);
    const [championFromData, setChampionFromData] = useState<Champion>();
    const [championToData, setChampionToData] = useState<Champion>();
    const [champion, setChampion] = useState<string | null>(null);
    const [inputChampion, setInputChampion] = React.useState('');
    const [patches, setPatches] = useState<any[] | null>([]);
    const [fromPatch, setFromPatch] = React.useState('');
    const [inputFromPatch, setInputFromPatch] = useState<string | null>(null);
    const [toPatch, setToPatch] = React.useState('');
    const [inputToPatch, setInputToPatch] = useState<string | null>(null);
    const inputRef = useRef(null)



    useEffect(() => {
        fetch('https://ddragon.leagueoflegends.com/api/versions.json', {})
            .then(response => response.json())
            .then(data => getChampions(data))
            .then(data => setPatches(data))

        // .then(data => setPatch(data[0]))
        function getChampions(patches: any) {
            fetch('https://ddragon.leagueoflegends.com/cdn/' + patches[0] + '/data/en_US/champion.json', {})
                .then(response => response.json())
                .then(data => getChampionNames(data))
                // @ts-ignore
                .then(data => setChampions(data))
                .catch(error => console.error(error));
            return patches
        }
    }, []);

    function getChampionNames(data: any) {
        let champArray = []
        for (const [key] of Object.entries(data.data)) {
            // @ts-ignore
            champArray.push(key)
        }
        return champArray
    }

    function getChampionData(champion: string | null, fromPatch: string | null, toPatch: string | null) {
        console.log("in getChampionData", champion, fromPatch, toPatch)
        if (champion !== "" && fromPatch !== "" && toPatch !== "") {
            getChampionFromData(champion, fromPatch)
            getChampionToData(champion, toPatch)
        }
    }

    function getChampionFromData(champion: string | null, fromPatch: string | null) {
        if (champion != null && champions != null && champions.includes(champion)) {
            fetch('https://ddragon.leagueoflegends.com/cdn/' + fromPatch + '/data/en_US/champion/' + champion + '.json', {})
                .then(response => response.json())
                .then(data => setChampionFromData(data.data[champion]))
                //.then(data => readChampdata(data))
                .catch(error => console.error(error));
        }
    }

    function getChampionToData(champion: string | null, toPatch: string | null) {
        if (champion != null && champions != null && champions.includes(champion)) {
            fetch('https://ddragon.leagueoflegends.com/cdn/' + toPatch + '/data/en_US/champion/' + champion + '.json', {})
                .then(response => response.json())
                .then(data => setChampionToData(data.data[champion]))
                //.then(data => readChampdata(data))
                .catch(error => console.error(error));
        }
    }

    return <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
        <div className="App">
            <header className="App-header">
                <span className="beau">
                    WELCOME TO PATCH CHANGES
                </span>
                <p className="spiegel">
                    Search a champion below to see the stat differences between patches
                </p>
                <Box sx={{mt: 2, display: 'flex'}}>
                    {champions ? <Autocomplete
                        disablePortal
                        freeSolo
                        value={champion}
                        onChange={(event: any, champion: string | null) => {
                            setChampion(champion);
                            getChampionData(champion, fromPatch, toPatch)
                        }}
                        inputValue={inputChampion}
                        onInputChange={(event, newInputChampion) => {
                            setInputChampion(newInputChampion);

                        }}
                        autoHighlight={true}
                        autoSelect={true}
                        id="combo-box-demo"
                        onKeyDown={(ev) => {
                            if (ev.key === 'Enter') {
                                // @ts-ignore
                                getChampionData(ev.target.value, fromPatch, toPatch)
                                ev.preventDefault();
                            }
                        }}
                        options={champions}
                        sx={{width: {xs:150, md: 200, lg: 300}}}
                        renderInput={(params) =>
                            <Box sx={{
                                display: 'flex',
                            }}
                            >
                                <TextField sx={{marginRight: '10px'}}{...params}
                                           label={<Typography variant="body2" className="spiegel"
                                                              color="textSecondary">Champion</Typography>}/>
                            </Box>}
                    /> : <div>Loading...</div>}
                    {patches ?
                        <Box sx={{display: 'flex'}}><Autocomplete
                            freeSolo
                            value={fromPatch || patches[1] || null}
                            onChange={(event: any, newValue: string | null) => {
                                setInputFromPatch(newValue);
                            }}
                            inputValue={fromPatch}
                            onInputChange={(event, newInputValue) => {
                                setFromPatch(newInputValue);
                                getChampionData(champion, newInputValue, toPatch)
                            }}
                            onKeyDown={(ev) => {
                                if (ev.key === 'Enter') {
                                    // @ts-ignore
                                    getChampionData(ev.target.value, fromPatch, toPatch)
                                    ev.preventDefault();
                                }
                            }}
                            options={patches}
                            sx={{width: {xs:100, md: 200, lg: 200}}}
                            renderInput={(params) =>
                                <Box sx={{
                                    display: 'flex',
                                }}
                                >
                                    <TextField sx={{marginRight: '10px'}}{...params}
                                               label={<Typography variant="body2" className="spiegel"
                                                                  color="textSecondary">From</Typography>}/>
                                </Box>}

                        />
                            <Autocomplete
                                disablePortal
                                freeSolo
                                value={toPatch || patches[0] || null}
                                onChange={(event: any, newValue: string | null) => {
                                    setInputToPatch(newValue);
                                }}
                                inputValue={toPatch}
                                onInputChange={(event, newInputValue) => {
                                    setToPatch(newInputValue);
                                    getChampionData(champion, fromPatch, newInputValue)
                                }}
                                ref={inputRef}
                                autoHighlight={true}
                                autoSelect={true}
                                onKeyDown={(ev) => {
                                    if (ev.key === 'Enter') {
                                        // @ts-ignore
                                        getChampionData(ev.target.value, fromPatch, toPatch)
                                        ev.preventDefault();
                                    }
                                }}
                                options={patches}
                                sx={{width: {xs:100, md: 200, lg: 200}}}
                                renderInput={(params) =>
                                    <Box sx={{
                                        display: 'flex',
                                    }}
                                    >
                                        <TextField sx={{marginRight: '10px'}}{...params}
                                                   label={<Typography variant="body2" className="spiegel"
                                                                      color="textSecondary">To</Typography>}/>
                                    </Box>}

                            /></Box> : <div>Loading...</div>}

                    {/*<Button onClick={() => getChampionData(champion, fromPatch, toPatch)}>Search</Button>*/}

                </Box>
            </header>


            {champion && championFromData && championToData && fromPatch && toPatch?
                <Container sx={{fontFamily: 'Siegel', mb: 5}}>
                    <Box
                        component="img"
                        src={`https://ddragon.leagueoflegends.com/cdn/${fromPatch}/img/champion/${champion}.png`}
                    />
                    <Typography sx={{fontFamily: 'Beau', textTransform:'uppercase', pb: 1}}>{championFromData['title']}</Typography>
                    {/* COOLDOWNS */}
                    <Grid container spacing={1}>
                        <Grid item xs={6} md={6} lg={6}>
                            <Toolbar sx={{
                                p: 1,
                                backgroundColor: '#463714'
                            }}
                            >
                                <Typography sx={{fontFamily: 'Beau'}}>SPELL COOLDOWNS PER RANK {fromPatch}</Typography>
                            </Toolbar>

                            <TableContainer component={Paper} sx={{backgroundColor: '#32281E', borderRadius: 0}}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Ability</TableCell>
                                            <TableCell sx={{fontFamily: 'Spiegel'}}>Cooldown Per Level (1-5)</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {championFromData['spells']
                                            .map((spell, index) => (
                                                <TableRow key={index}>
                                                    <TableCell><Box
                                                        component="img"
                                                        sx={{width: '40px'}}
                                                        src={`https://ddragon.leagueoflegends.com/cdn/${fromPatch}/img/spell/${ spell.id }.png`}
                                                    /><Typography sx={{fontFamily: 'Spiegel'}}>{spell.name}</Typography></TableCell>
                                                    <TableCell>
                                                        {spell.cooldown.map((cooldown, index) =>
                                                            <Typography key={index}
                                                                sx={{
                                                                    display: 'inline',
                                                                    fontFamily: 'Spiegel'

                                                                }}>{cooldown}{index < spell.cooldown.length - 1 ? " / " : ""}</Typography>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>


                        {/* DAMAGE */}

                        <Grid item xs={6} md={6} lg={6}>
                            <Toolbar sx={{
                                p: 1,
                                backgroundColor: '#463714'
                            }}
                            >
                                <Typography sx={{fontFamily: 'Beau'}}>SPELL COOLDOWNS PER RANK {toPatch}</Typography>
                            </Toolbar>

                            <TableContainer component={Paper} sx={{backgroundColor: '#32281E', borderRadius: 0}}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Ability</TableCell>
                                            <TableCell sx={{fontFamily: 'Spiegel'}}>Cooldown Per Level (1-5)</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {championToData['spells']
                                            .map((spell, index) => (
                                                <TableRow key={index}>
                                                    <TableCell><Box
                                                        component="img"
                                                        key={spell.name}
                                                        sx={{width: '40px'}}
                                                        src={`https://ddragon.leagueoflegends.com/cdn/${toPatch}/img/spell/${spell.image.full}`}
                                                    /><Typography sx={{fontFamily: 'Spiegel'}}>{spell.name}</Typography></TableCell>
                                                    <TableCell>
                                                        {spell.cooldown.map((cooldown, index) =>
                                                            <Typography key={index}
                                                                sx={{
                                                                    display: 'inline',
                                                                    fontFamily: 'Spiegel'
                                                                }}>{cooldown}{index < spell.cooldown.length - 1 ? " / " : ""}</Typography>
                                                        )}
                                                    </TableCell>
                                                </TableRow>

                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Container>
                : ""}
        </div>
    </ThemeProvider>;
}

export default App;
